import * as React from "react";
import PageLayout from "../components/PageLayout";
import Seo from "../components/Seo";

const ContactPage = (props) => {
  const params = new URLSearchParams(props.location.search);
  const interestedIn = params.get("bike") ? params.get("bike") : "";
  return (
    <PageLayout>
      <Seo title="Contact Us" slug="contact" />
      <section className="contact__main-wrapper">
        <div className="contact__main">
          <h3>Contact Papa Monkey</h3>
          <form
            action="/thank-you"
            name="Contact Form"
            method="POST"
            data-netlify="true"
          >
            <input type="hidden" name="form-name" value="Contact Form" />
            <input
              type="hidden"
              name="interested-in-bike"
              value={interestedIn}
            />
            <div className="inline-inputs">
              <div className="input-group">
                <label htmlFor="name">name</label>
                <input type="text" name="name" required />
              </div>
              <div className="input-group">
                <label htmlFor="email">email</label>
                <input type="text" name="email" required />
              </div>
            </div>
            <div className="input-group">
              <label htmlFor="message">message</label>
              <textarea name="message" id="" cols="30" rows="10"></textarea>
            </div>
            <div className="buttons">
              <button className="button">Send</button>
            </div>
          </form>
        </div>
      </section>
    </PageLayout>
  );
};

export default ContactPage;
